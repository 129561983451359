import Retool from 'react-retool';
import './App.css';
import { useState, useEffect } from 'react';

const sampleData = { client: 'xpnchassisus' };
const domain = 'https://retool.sometestdima.shop';

function App() {
  const mockFunction = function (data: any) {
    console.log(`Mock function: ${data}`);
  };

  const [url, setUrl] = useState('');

  useEffect(() => {
    const currentUrl = window.location.href;
    console.log(`Current: ${currentUrl}`);

    const baseDomain = 'https://client2.sometestdima.shop';

    const pathAfterDomain = currentUrl.split(baseDomain)[1] || '';

    const iframeUrl = `${domain}${pathAfterDomain}`;

    console.log(`iframeUrl: ${iframeUrl}`);
    setUrl(iframeUrl);
  }, []);

  window.addEventListener('load', () => {
    const iframe = document.querySelector('iframe');

    if (iframe && iframe.contentWindow) {
      try {
        const iframeRootDiv =
          iframe.contentWindow.document.querySelector('#root > div');
        if (iframeRootDiv) {
          iframeRootDiv.style.overflow = 'hidden';
        }
      } catch (error) {}
    }
  });

  return (
    <div style={{ height: '100vh', width: '100vw', overflow: 'hidden' }}>
      {url && (
        <Retool
          styling={{ overflow: 'hidden' }}
          url={url}
          data={sampleData}
          onData={(data) => mockFunction(data)}
        />
      )}
    </div>
  );
}

export default App;
